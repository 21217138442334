<template>
  <Section class="expertise-card flex-col flex-center h-center">
    <div class="wrapper">
      <h2 class="title">
        Une
        <strong>expertise</strong>
        qualifiée et reconnue
      </h2>
      <ExpertisesCard :displayReceipts="displayReceipts" />
    </div>
  </Section>
</template>

<script>
export default {
  props: {
    displayReceipts: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="less" scoped>
.expertise-card {
  width: 100%;
  color: #272727;

  .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}

.title {
  font-weight: var(--ds-weight-semi-bold);
  font-size: 24px;
  text-align: center;
  margin: 0 0 23px;
  width: 100%;
}

.sub-title {
  text-transform: uppercase;
  font-weight: var(--ds-weight-semi-bold);
  font-size: 16px;
  margin: 0 0 10px 15px;
  align-self: flex-start;
}

@media @bp-desktop {
  .wrapper {
    max-width: @bp-desktop-max-width;
    .title {
      font-size: 32px;
      margin-bottom: 45px;
    }
  }

  .expertises {
    margin-bottom: 45px;
  }
}
</style>
