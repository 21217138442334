<template>
  <div class="expertises-card flex-col h-center">
    <ul class="list-container">
      <li>
          <div class="xlarge">5</div>
          <div class="large">années<br>d’existence</div>
      </li>
      <li>
          <div class="xlarge">+250 000</div>
          <div class="large">utilisateurs</div>
      </li>
      <li>
          <div class="xlarge">40</div>
          <div class="large">experts<br>à votre service</div>
      </li>
      <li>
          <div class="xlarge">10 000 000</div>
          <div class="large">de simulations</div>
      </li>
      <li v-if="displayReceipts">
          <div class="xlarge">5 000</div>
          <div class="large">quittances /mois<br>envoyées</div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    displayReceipts: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="less" scoped>
.expertises-card {
  padding: 35px;
  width: 100%;
  background-color: var(--ds-color-primary-25);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  border-radius: 12px;
}

.large {
  font-weight: 500;
  font-size: 20px;
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.xlarge {
  font-weight: var(--ds-weight-semi-bold);
  font-size: 38px;
  white-space: nowrap;
  text-align: center;
  color: var(--ds-color-primary-100);
}


@media @bp-md-down {
  .large {
    font-size: 18px;
  }

  .xlarge {
    font-size: 34px;
  }
}

.list-container {
display: flex;
gap: 28px;
justify-content: space-evenly;
  flex-wrap: wrap;
width: 100%;
}

li {
display: flex;
flex-direction: column;
align-items: center;
}

.tick-icon {
margin-right: 15px;
}

@media @bp-desktop {
  .expertises-card {
    position: relative;
    padding: 40px;
    font-weight: 400;
    font-size: 24px;

    &:after {
      content: '';
      width: 314px;
      height: 270px;
      position: absolute;
      top: 0;
      right: 0;
      filter: invert(100%);
      mix-blend-mode: soft-light;
    }
  }

  li:last-child {
    margin-bottom: 0;
  }

  .tick-icon {
    width: 25px;
    height: 25px;
    margin-right: 20px;
  }
}
</style>
